import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Initialize Firebase
// const firebaseConfig = {
//   apiKey: 'AIzaSyD8SoqUsleW65_eI1idb0_r8vkqLPuJan8',
//   authDomain: 'paytalk-429bf.firebaseapp.com',
//   projectId: 'paytalk-429bf',
//   databaseURL: 'https://paytalk-429bf.firebaseio.com',
//   storageBucket: 'paytalk-429bf.appspot.com',
//   messagingSenderId: '793481823711',
//   appId: '1:793481823711:web:e75de25447baee1a51b06a',
//   measurementId: 'G-MBNKKCNX8V',
// }

const firebaseConfig = {
  apiKey: "AIzaSyANOP0TXFDo16boZkiqtiGI6v2NAidsc18",
  authDomain: "reallyme-66efd.firebaseapp.com",
  databaseURL: "https://reallyme-66efd.firebaseio.com",
  projectId: "reallyme-66efd",
  storageBucket: "reallyme-66efd.appspot.com",
  messagingSenderId: "506824883755",
  appId: "1:506824883755:web:fc18583ad419550c0f346e",
  measurementId: "G-28PQX2VQK1",
};

firebase.initializeApp(firebaseConfig);
