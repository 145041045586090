import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export default {
  namespaced: true,
  state: {
    currentUser: null,
    currentOrganization: null,
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setCurrentOrganization(state, payload) {
      state.currentOrganization = payload;
    },
  },
  actions: {
    async fetchCurrentUser({ commit }, userId) {
      //   const userId = await firebase.auth().currentUser.id;
      console.log(userId);
      const snapshot = await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .get();
      if (snapshot.exists) {
        const user = { uuid: snapshot.id, ...snapshot.data() };
        commit("setCurrentUser", user);
      } else {
        throw new Error("User not found");
      }
    },
    async fetchCurrentOrganization({ commit, state }) {
      try {
        console.log("Fetch current user organization");
        console.log(state.currentUser);
        const organizationId = state.currentUser.org_id;
        console.log(organizationId);
        const snapshot = await firebase
          .firestore()
          .collection("Organizations")
          .doc(organizationId)
          .get();
        if (snapshot.exists) {
          const organization = { id: snapshot.id, ...snapshot.data() };
          console.log("Has the snapshot");
          commit("setCurrentOrganization", organization);
        } else {
          throw new Error("Organization not found");
        }
      } catch (error) {
        console.log("organization fetch error");
        console.log(error);
      }
    },
    clearAuth({ commit }) {
      try {
        commit("setCurrentUser", null);
        commit("setCurrentOrganization", null);
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    isDefaultAdmin: (state) =>
      state.currentOrganization.defaultAdmin ===
      firebase.auth().currentUser.uid,
  },
};
