import Vue from "vue";
import VueRx from "vue-rx";
import VuejsClipper from "vuejs-clipper";

Vue.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperFixed: true,
    clipperUpload: true,
  },
});
Vue.use(VueRx);
