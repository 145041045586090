<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  }),
};
</script>
<style>
.top-note {
  padding: 0.3em;
  border-bottom: 3px solid #dedede;
  margin: 0;
}
</style>
