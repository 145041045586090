import Vue from "vue";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";

// Add the required rule
extend("required", {
  ...required,
  message: messages["required"],
});

// Add the email rule
extend("email", {
  ...email,
  message: messages["email"],
});

extend("positive", {
  validate: (value) => {
    if (value >= 0) {
      return true;
    }
    return false;
  },
  message: "Please enter positive number",
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
