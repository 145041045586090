import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      {
        path: "/",
        name: "dashboard",
        meta: { title: "Dashboard", requiresAuth: true },
        component: () => import("../views/Dashboard.vue"),
      },
      {
        path: "/corporate-users",
        name: "corporate-users",
        meta: { title: "Corporate Users", requiresAuth: true },
        component: () => import("../views/CorporateUsers.vue"),
      },
      {
        path: "/usage-and-billings",
        name: "usage-and-billings",
        meta: { title: "Usage and Billings", requiresAuth: true },
        component: () => import("../views/UsageAndBillings.vue"),
      },
      {
        path: "/settings",
        name: "settings",
        meta: { title: "Settings", requiresAuth: true },
        component: () => import("../views/Settings.vue"),
      },
    ],
  },
  {
    path: "",
    component: () => import("../layouts/FullLayout.vue"),
    children: [
      {
        path: "/login",
        name: "page-login",
        props: { isLogin: true },
        // meta: { hideOnAuth: true },
        component: () => import("../components/Authentication.vue"),
      },
      {
        path: "/onboard/settings",
        meta: { requiresAuth: true },
        component: () => import("../views/onboard/Settings.vue"),
      },
      {
        path: "/onboard/payment",
        meta: { requiresAuth: true },
        component: () => import("../views/onboard/Payment.vue"),
      },
    ],
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const getCurrentUser = () =>
  new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });

router.beforeEach(async (to, from, next) => {
  const firebaseCurrentUser = await getCurrentUser();
  // TODO: prevent navigation if not profile or initial password set for requierd auth routes
  if (to.meta.requiresAuth && !firebaseCurrentUser) {
    return next("login");
  }
  if (to.meta.hideOnAuth && firebaseCurrentUser) {
    return next("/");
  }
  return next();
});
// eslint-disable-next-line no-unused-vars
router.afterEach((to, _) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "Truya";
  });
});

export default router;
